body,
html {
  height: 100%;
  margin: 0px;
}

body,
button,
html,
input,
select,
textarea {
  /*color: white;*/
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  background-color: #0079bf;
}

.surface {
  display: flex;
  flex-direction: column;
}

.header-container {
  display: block;
}

.header-nav {
  background-color: rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  display: flex;
  min-height: 40px;
  justify-content: space-between;
  position: relative;
  padding: 5px;
}

.left-nav {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
}

.left-nav-item {
  background: 0 0;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-weight: 700;
  height: 15px;
  line-height: 15px;
  margin: 0 4px 5px 0;
  padding: 8px;
  text-decoration: none;
  text-transform: uppercase;
}

.search-container {
  position: relative;
}

.search-input {
  color: rgba(255, 255, 255, 0.5);
  display: block;
  float: left;
  height: 32px;
  width: 180px;
  background: rgba(255, 255, 255, 0.3) none repeat scroll 0% 0% / auto
    padding-box border-box;
  border: 0px none rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  padding: 8px 30px 8px 8px;
  transition: width 0.15s ease 0s;
}

.search-btn-container {
  position: absolute;
  right: 2px;
}

.search-btn {
  color: rgb(255, 255, 255);
  display: block;
  height: 32px;
  width: 28px;
  font: 700 12px / 16px -apple-system, system-ui, "Segoe UI", Roboto,
    "Noto Sans", Ubuntu, "Droid Sans", "Helvetica Neue", sans-serif;
}

.search-btn-icon {
  font: 20px / 32px trellicons;
}

.search-btn-icon:before {
  content: "\E957";
}

.logo-nav {
  display: block;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  top: 5px;
}

.logo-nav-icon {
  background-image: url(/images/trello-icon.gif);
  background-size: 18px 18px;
  display: inline-block;
  opacity: 0;
  height: 18px;
  right: 59px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.logo-nav-img {
  background-image: url(/images/trello-logo.png);
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: 80px 30px;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  width: 80px;
}

.logo-nav-img:hover {
  opacity: 0.5;
}

.right-nav {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.right-nav-btn {
  border-radius: 3px;
  border: 0;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  text-decoration: none;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.3);
  box-shadow: none;
  color: #fff;
  display: flex;
  font-weight: 700;
  height: 32px;
  line-height: 32px;
  margin: 0 4px 0 0;
  padding: 0;
  transition: 0.1s ease;
  white-space: nowrap;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.3),
    hsla(0, 0%, 100%, 0.2)
  );
  padding: 0 12px;
}

.right-nav-btn.green {
  background: linear-gradient(180deg, #61bd4f 0, #5aac44);
}
.right-nav-btn:hover {
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.2),
    hsla(0, 0%, 100%, 0.1)
  );
}
.green:hover {
  background: linear-gradient(180deg, #5aac44 0, #519839);
}
.header-title {
}
.content {
  /*margin-top: 10px;*/
  position: relative;
  overflow-y: auto;
  outline: none;
  flex-grow: 1;
}
.board-wrapper {
  position: relative;
  /*position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;*/
}
.board-main-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 0;
  transition: margin 0.1s ease-in;
}
.board-canvas {
  position: relative;
  flex-grow: 1;
}
.board {
  user-select: none;
  white-space: nowrap;
  margin-bottom: 8px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 8px;
  /*position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;*/
}

.board-header-btn-divider {
  float: left;
  border-left: 1px solid hsla(0, 0%, 100%, 0.24);
  height: 16px;
  margin: 8px 8px 12px 4px;
}

.board-header {
  height: auto;
  padding: 8px 4px 4px 8px;
  position: relative;
  transition: padding 0.1s ease-in;
}

.board-header-btn {
  border-radius: 3px;
  color: #fff;
  cursor: default;
  float: left;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  margin: 0 4px 4px 0;
  overflow: hidden;
  padding-left: 32px;
  position: relative;
  text-decoration: none;
}

.board-header-btn.mod-board-name {
  background: transparent;
  cursor: default;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  padding: 0;
  text-decoration: none;
  max-width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.board-header-btn.mod-board-name .board-header-btn-text {
  padding: 0 12px;
}

.board-header-btn .board-name-input {
  display: none;
  background-color: #fff;
  border: 0;
  font-weight: 700;
  font-size: 18px;
  height: 32px;
  margin: 0;
  padding: 0 12px;
}

.board-header-facepile {
  cursor: default;
  float: left;
  margin-left: 4px;
  overflow: hidden;
  padding: 2px 0 0 2px;
}

.board-header-facepile .member {
  /*margin: 0 0 0 -2px;
  float: left;
  z-index: 1;*/
}
.list-wrapper {
  width: 272px;
  margin: 0 4px;
  height: 100%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}

.list-wrapper.list-empty-btn,
.list-wrapper.list-empty-compose,
.list-wrapper.list-update-compose {
  background-color: hsla(0, 0%, 100%, 0.24);
  cursor: pointer;
  border-radius: 3px;
  height: auto;
  min-height: 32px;
  padding: 4px;
  transition: background 85ms ease-in, opacity 40ms ease-in,
    border-color 85ms ease-in;
}

.list-wrapper.list-update-compose {
  margin: 0 !important;
}

.list-wrapper.list-empty-compose,
.list-wrapper.list-update-compose {
  background-color: #ebecf0;
}

.list-wrapper.list-empty-btn:hover {
  background-color: hsla(0, 0%, 100%, 0.32);
}

.btn-add-list {
  text-decoration-line: none;
  color: #fff;
}

.btn-add-list .placeholder {
  color: #fff;
  padding: 6px 8px;
  transition: color 85ms ease-in;
  display: block;
}

.btn-add-list .placeholder .placeholder-icon {
  color: #fff;
  margin-right: 2px;
}

.add-list-input {
  background: #fff;
  border: none;
  box-shadow: inset 0 0 0 2px #0079bf;
  display: block;
  margin: 0;
  transition: margin 85ms ease-in, background 85ms ease-in;
  width: 100%;
  padding: 8px 12px;
  color: #172b4d;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: 3px;
}

.add-list-buttons {
  margin-top: 10px;
}

.add-list-buttons .add-list-button-add {
  background-color: #5aac44;
  box-shadow: none;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 20px;
  padding: 6px 12px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
}

.add-list-buttons .add-list-button-add:hover {
  background-color: #61bd4f;
}

.add-list-buttons .add-list-button-remove {
  color: #6b778c;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  float: right;
}

.add-list-buttons .add-list-button-remove:hover {
  color: #172b4d;
  text-decoration: none;
}

.list-wrapper:first-child {
  margin-left: 8px;
}
.list {
  background-color: #ebecf0;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
  white-space: normal;
}

.list-header {
  flex: 0 0 auto;
  padding: 10px 8px;
  position: relative;
  min-height: 20px;
}

.list-header h2 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  margin: 0 0 8px;
  color: #172b4d;
  display: block;
}

.list-header-extras {
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 1;
}

.list-header-extras-menu {
  color: #6b778c;
  float: left;
  padding: 6px;
  border-radius: 3px;
}

.list-cards {
  flex: 1 1 auto;
  margin-bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 4px;
  padding: 0 4px;
  z-index: 1;
  min-height: 0;
}

.list-cards-droppable{
  min-height: 5px
}

.card-composer-container {
  min-height: 38px;
  max-height: 38px;
  display: flex;
  justify-content: space-between;
}

.btn-card-composer {
  border-radius: 3px;
  color: #5e6c84;
  display: block;
  flex: 1 0 auto;
  margin: 2px 0 8px 8px;
  padding: 4px 8px;
  position: relative;
  text-decoration: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.card-composer-container .icon-template-card {
  color: #6b778c;
  padding: 4px;
  margin-top: 2px;
  margin-right: 8px;
  cursor: pointer;
}

.card-details-textarea,
card-details-textarea:focus {
  background: none;
  border: none;
  box-shadow: none;
  height: auto;
  margin-bottom: 4px;
  max-height: 162px;
  min-height: 54px;
  overflow-y: auto;
  padding: 0;
}

.card-composer,
.card-composer-editor {
  padding-bottom: 8px;
}

.card-buttons {
  margin-top: 10px;
}

.card-buttons .card-button-add {
  background-color: #5aac44;
  box-shadow: none;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 20px;
  padding: 6px 12px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
}

.card-buttons .card-button-add:hover {
  background-color: #61bd4f;
}

.card-buttons .card-button-remove {
  color: #6b778c;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.card-buttons .card-button-remove:hover {
  color: #172b4d;
  text-decoration: none;
}

.card-buttons .card-button-more {
  border: none;
  background-color: transparent;
  float: right;
  color: #6b778c;
  outline: none;
  cursor: pointer;
}

.card {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
  cursor: pointer;
  display: block;
  margin-bottom: 8px;
  max-width: 300px;
  min-height: 20px;
  position: relative;
  text-decoration: none;
  z-index: 0;
}

.card-details {
  overflow: hidden;
  padding: 6px 8px 2px;
  position: relative;
  z-index: 10;
}

.card-title {
  clear: both;
  display: block;
  margin: 0 0 4px;
  overflow: hidden;
  text-decoration: none;
  word-wrap: break-word;
  color: #172b4d;
}

.card-btn-edit {
  background-color: #f4f5f7;
  background-clip: padding-box;
  background-origin: padding-box;
  border-radius: 3px;
  opacity: 0.8;
  padding: 4px;
  position: absolute;
  right: 2px;
  top: 2px;
  visibility: hidden;
  z-index: 40;
}

.card-details:hover .card-btn-edit {
  visibility: initial;
}

.card-badges {
  float: left;
  max-width: 100%;
  margin-left: -2px;
}

.card-badge {
  color: #5e6c84;
  display: inline-block;
  margin: 0 4px 4px 0;
  max-width: 100%;
  min-height: 20px;
  overflow: hidden;
  position: relative;
  padding: 2px;
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: top;
}

.card-badge-icon {
  color: #6b778c;
  vertical-align: top;
}

.card-badge-text {
  color: #6b778c;
  vertical-align: top;
}

.card-members {
  float: right;
  margin: 0 -2px 0 0;
}

.card-members .member {
  height: 28px;
  width: 28px;
  float: right;
  margin: 0 0 4px 4px;
}

.card-members .member .member-initials {
  height: 28px;
  line-height: 28px;
  width: 28px;
}

.member {
  background-color: #dfe1e6;
  border-radius: 25em;
  color: #172b4d;
  cursor: pointer;
  display: block;
  float: left;
  height: 32px;
  margin: 0 4px 4px 0;
  overflow: visible;
  position: relative;
  width: 32px;
  text-decoration: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: 0;
}

.member-initials {
  display: block;
  font-size: 12px;
  font-weight: 700;
  height: 32px;
  left: 0;
  line-height: 32px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}

.modal-up .modal-overlay {
  display: flex;
}

.modal-overlay {
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.64);
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

.modal {
  background-color: #f4f5f7;
  border-radius: 2px;
  display: none;
  margin: 48px 0 80px;
  overflow: hidden;
  position: relative;
  width: 768px;
  z-index: 25;
}

.modal-wrapper {
  display: block;
}

.modal-close-button {
  color: #42526e;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  height: 32px;
  overflow: hidden;
  padding: 4px;
  margin: 4px;
  width: 32px;
  z-index: 2;
  cursor: pointer;
  transition: background-color 85ms, color 85ms;
}

.modal-detail {
  min-height: 600px;
}

.modal-header {
  margin: 12px 40px 8px 56px;
  min-height: 32px;
  position: relative;
  z-index: 1;
}

.modal-header-icon {
  left: -40px;
  position: absolute;
  top: 4px;
}

.modal-title {
  margin: 4px 0 0;
  padding: 8px 0 0;
}

.modal-title h2 {
  background: transparent;
  border-radius: 3px;
  box-shadow: none;
  font-size: 20px;
  font-weight: 600;
  height: 32px;
  line-height: 24px;
  margin: -4px -8px;
  min-height: 24px;
  padding: 4px 8px;
  resize: none;
}

.modal-inline-title {
  cursor: default;
  display: inline-block;
  margin: 4px 8px 4px 2px;
}

.modal-main {
  float: left;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 24px;
  padding: 0 8px 8px 16px;
  position: relative;
  width: 552px;
  z-index: 0;
}
.modal-description {
  clear: both;
  margin-bottom: 24px;
  position: relative;
}

.modal-description-title {
  display: flex;
  align-items: center;
  min-height: 32px;
  border-bottom: 1px solid rgba(9, 30, 66, 0.13);
  padding: 8px 0;
  position: relative;
  margin: 0 0 4px 40px;
  border-bottom: none;
}

.modal-description-title-icon {
  left: -40px;
  position: absolute;
  top: 8px;
}

.modal-module-title h3 {
  display: inline-block;
  width: auto;
  margin: 0;
  min-height: 18px;
  min-width: 40px;
}

.modal-sidebar {
  float: right;
  padding: 0 16px 8px 8px;
  width: 168px;
  overflow: hidden;
  z-index: 10;
}

.modal-actions {
  clear: both;
  margin-bottom: 24px;
  position: relative;
}

.action-button-link {
  background-color: rgba(9, 30, 66, 0.04);
  box-shadow: none;
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 32px;
  margin-top: 8px;
  max-width: 300px;
  overflow: hidden;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
}

.action-button-link:hover {
  background-color: rgba(9, 30, 66, 0.08);
  box-shadow: none;
  border: none;
}
