@font-face {
  font-family: trellicons;
  src: url(/fonts/trellicons.01f4873da4f2b2c57352.eot);
  src: url(/fonts/trellicons.01f4873da4f2b2c57352.eot#iefix)
      format("embedded-opentype"),
    url(/fonts/trellicons.f95b53426037fc215170.ttf) format("truetype"),
    url(/fonts/trellicons.c914986d44ff5c34e6f8.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
.icon-lg,
.icon-md,
.icon-sm {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: trellicons;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  vertical-align: bottom;
}
.icon-lg.mod-quiet,
.icon-md.mod-quiet,
.icon-sm.mod-quiet {
  color: #6b778c;
}
.icon-lg {
  font-size: 24px;
}
.icon-lg,
.icon-md {
  height: 32px;
  line-height: 32px;
  width: 32px;
}
.icon-md {
  font-size: 20px;
}
.icon-sm {
  height: 20px;
  font-size: 16px;
  line-height: 20px;
  width: 20px;
}
.flip-icon .icon-sm {
  transform: scaleX(-1);
}
.icon-activity:before {
  content: "\E900";
}
.icon-add:before {
  content: "\E901";
}
.icon-add-member:before {
  content: "\E902";
}
.icon-add-reaction:before {
  content: "\E903";
}
.icon-admin-chevron:before {
  content: "\E904";
}
.icon-app-store:before {
  content: "\E905";
}
.icon-application-switcher:before {
  content: "\E906";
}
.icon-archive:before {
  content: "\E907";
}
.icon-attachment:before {
  content: "\E908";
}
.icon-back:before {
  content: "\E909";
}
.icon-board:before {
  content: "\E90A";
}
.icon-box:before {
  content: "\E90B";
}
.icon-business-class:before {
  content: "\E90C";
}
.icon-butler-bot:before {
  content: "\E90D";
}
.icon-calendar:before {
  content: "\E90E";
}
.icon-camera:before {
  content: "\E90F";
}
.icon-card:before {
  content: "\E910";
}
.icon-card-cover:before {
  content: "\E911";
}
.icon-card-recurring:before {
  content: "\E912";
}
.icon-check:before {
  content: "\E913";
}
.icon-checkbox-checked:before {
  content: "\E914";
}
.icon-checkbox-unchecked:before {
  content: "\E915";
}
.icon-checklist:before {
  content: "\E916";
}
.icon-clock:before {
  content: "\E917";
}
.icon-close:before {
  content: "\E918";
}
.icon-collection:before {
  content: "\E919";
}
.icon-comment:before {
  content: "\E91A";
}
.icon-copy:before {
  content: "\E91B";
}
.icon-custom-field:before {
  content: "\E91C";
}
.icon-description:before {
  content: "\E91D";
}
.icon-documents:before {
  content: "\E91E";
}
.icon-down:before {
  content: "\E91F";
}
.icon-download:before {
  content: "\E920";
}
.icon-dropbox:before {
  content: "\E921";
}
.icon-dropdown-options:before {
  content: "\E922";
}
.icon-edit:before {
  content: "\E923";
}
.icon-email:before {
  content: "\E924";
}
.icon-emoji:before {
  content: "\E925";
}
.icon-enterprise:before {
  content: "\E926";
}
.icon-external-link:before {
  content: "\E927";
}
.icon-facebook:before {
  content: "\E928";
}
.icon-filter:before {
  content: "\E929";
}
.icon-fogbugz:before {
  content: "\E92A";
}
.icon-forward:before {
  content: "\E92B";
}
.icon-gear:before {
  content: "\E92C";
}
.icon-github:before {
  content: "\E92D";
}
.icon-google:before {
  content: "\E92E";
}
.icon-google-drive:before {
  content: "\E92F";
}
.icon-heart:before {
  content: "\E930";
}
.icon-help:before {
  content: "\E931";
}
.icon-home:before {
  content: "\E932";
}
.icon-house:before {
  content: "\E933";
}
.icon-image:before {
  content: "\E934";
}
.icon-information:before {
  content: "\E935";
}
.icon-instagram:before {
  content: "\E936";
}
.icon-kiln:before {
  content: "\E937";
}
.icon-label:before {
  content: "\E938";
}
.icon-leave-board:before {
  content: "\E939";
}
.icon-lightbulb:before {
  content: "\E93A";
}
.icon-lightbulb-lit:before {
  content: "\E93B";
}
.icon-list:before {
  content: "\E93C";
}
.icon-location:before {
  content: "\E93D";
}
.icon-log-out:before {
  content: "\E93E";
}
.icon-member:before {
  content: "\E93F";
}
.icon-mention:before {
  content: "\E940";
}
.icon-microphone:before {
  content: "\E941";
}
.icon-microphone-off:before {
  content: "\E942";
}
.icon-move:before {
  content: "\E943";
}
.icon-nearby:before {
  content: "\E944";
}
.icon-notification:before {
  content: "\E945";
}
.icon-number:before {
  content: "\E946";
}
.icon-onedrive:before {
  content: "\E947";
}
.icon-open-source:before {
  content: "\E948";
}
.icon-organization:before {
  content: "\E949";
}
.icon-organization-visible:before {
  content: "\E94A";
}
.icon-overflow-menu-horizontal:before {
  content: "\E94B";
}
.icon-overflow-menu-vertical:before {
  content: "\E94C";
}
.icon-password-manager:before {
  content: "\E94D";
}
.icon-power-up:before {
  content: "\E94E";
}
.icon-preferences:before {
  content: "\E94F";
}
.icon-private:before {
  content: "\E950";
}
.icon-public:before {
  content: "\E951";
}
.icon-refresh:before {
  content: "\E952";
}
.icon-remove:before {
  content: "\E953";
}
.icon-remove-member:before {
  content: "\E954";
}
.icon-reply:before {
  content: "\E955";
}
.icon-screenshare:before {
  content: "\E956";
}
.icon-search:before {
  content: "\E957";
}
.icon-selection-mode:before {
  content: "\E958";
}
.icon-send:before {
  content: "\E959";
}
.icon-share:before {
  content: "\E95A";
}
.icon-sparkle:before {
  content: "\E95B";
}
.icon-star:before {
  content: "\E95C";
}
.icon-starred:before {
  content: "\E95D";
}
.icon-sticker:before {
  content: "\E95E";
}
.icon-subscribe:before {
  content: "\E95F";
}
.icon-sync:before {
  content: "\E960";
}
.icon-taco:before {
  content: "\E961";
}
.icon-template-board:before {
  content: "\E962";
}
.icon-template-card:before {
  content: "\E963";
}
.icon-template-create:before {
  content: "\E964";
}
.icon-text:before {
  content: "\E965";
}
.icon-trash:before {
  content: "\E966";
}
.icon-trello-gold:before {
  content: "\E967";
}
.icon-twitter:before {
  content: "\E968";
}
.icon-up:before {
  content: "\E969";
}
.icon-upload:before {
  content: "\E96A";
}
.icon-video:before {
  content: "\E96B";
}
.icon-video-off:before {
  content: "\E96C";
}
.icon-vote:before {
  content: "\E96D";
}
.icon-warning:before {
  content: "\E96E";
}
.icon-custom {
  text-align: center;
  vertical-align: middle;
}
.icon-custom:before {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}
.icon-custom.icon-sm {
  padding: 2px;
}
.icon-custom.icon-sm,
.icon-custom.icon-sm:before {
  height: 16px;
  width: 16px;
}
.icon-custom.icon-lg {
  padding: 4px;
}
.icon-custom.icon-lg,
.icon-custom.icon-lg:before {
  height: 24px;
  width: 24px;
}
.icon-custom.icon-checkbox-indeterminate:before {
  background-image: url(https://a.trellocdn.com/prgb/dist/images/icon_checkbox_indeterminate.8f60248d6982b1489b6d.svg);
}
.icon-lg,
.icon-md,
.icon-sm {
  color: #42526e;
}
.dark-hover:hover .icon-lg,
.dark-hover:hover .icon-md,
.dark-hover:hover .icon-sm,
.icon-lg.dark,
.icon-lg.dark-hover:hover,
.icon-md.dark,
.icon-md.dark-hover:hover,
.icon-sm.dark,
.icon-sm.dark-hover:hover {
  color: #172b4d;
  text-decoration: none;
}
.icon-lg.light,
.icon-lg.light-hover:hover,
.icon-md.light,
.icon-md.light-hover:hover,
.icon-sm.light,
.icon-sm.light-hover:hover,
.light-hover:hover .icon-lg,
.light-hover:hover .icon-md,
.light-hover:hover .icon-sm {
  color: #fff;
  text-decoration: none;
}
.dark-background-hover:hover .icon-lg,
.dark-background-hover:hover .icon-md,
.dark-background-hover:hover .icon-sm,
.icon-lg.dark-background-hover:hover,
.icon-md.dark-background-hover:hover,
.icon-sm.dark-background-hover:hover {
  background-color: rgba(9, 30, 66, 0.08);
  color: #172b4d;
  border-radius: 3px;
  text-decoration: none;
}
.dark-background-hover:active .icon-lg,
.dark-background-hover:active .icon-md,
.dark-background-hover:active .icon-sm,
.icon-lg.dark-background-hover:active,
.icon-md.dark-background-hover:active,
.icon-sm.dark-background-hover:active {
  background-color: rgba(9, 30, 66, 0.13);
  color: #172b4d;
  border-radius: 3px;
  text-decoration: none;
}
.icon-private {
  color: #eb5a46;
}
.dark-hover:hover .icon-private,
.icon-private.dark-hover:hover {
  color: #cf513d;
}
.icon-organization-visible {
  color: #f2d600;
}
.dark-hover:hover .icon-organization-visible,
.icon-organization-visible.dark-hover:hover {
  color: #e6c60d;
}
.icon-public {
  color: #61bd4f;
}
.dark-hover:hover .icon-public,
.icon-public.dark-hover:hover {
  color: #5aac44;
}
.icon-star-active {
  color: #f2d600;
}
.icon-business-class-color {
  color: #6c547b;
}
.icon-trello-gold-color {
  color: #d9b51c;
}
.icon-facebook-color {
  color: #3b5998;
}
.icon-google-color {
  color: #e11a31;
}
.icon-twitter-color {
  color: #00aced;
}

.u-bottom {
  margin-bottom: 0;
  padding-bottom: 0;
}

.u-inline-block {
  display: inline-block;
}
p {
  margin: 0 0 8px;
}

h3,
h4,
h5,
h6 {
  font-size: 16px;
  line-height: 20px;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin: 0 0 8px;
}

textarea {
  resize: vertical;
  width: 100%;
}

.iframe-input:focus,
input:not([type="file"]):focus,
textarea:focus {
  outline: 0;
}

.hide {
  display: none !important;
}
